import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@shared/models/select.model';

@Component({
  selector: 'kp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() public options: ISelectOption[] = [];
  @Input() public separateFirstElement: boolean = false;
  @Input() public separateLastElement: boolean = false;

  @Output() public optionClicked: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>();

  defaultIconColor: string = '#A33A40';
  defaultTextColor: string = '#171619';
}
