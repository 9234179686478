<div class="contents">
  <div class="contents__content">
    <h1>Политика конфиденциальности</h1>
    <p>
      Политика конфиденциальности Общества с ограниченной ответственностью «Первые Леди» (сокращенное наименование -
      <span class="bold">ООО «Первые Леди»</span> (ИНН 9705213124, ОГРН 1237700833028) (далее - Администрация),
      разработана в соответствии с положениями законодательства Российской Федерации и Правилами использования сайта,
      размещенными на сайте по адресу:
      <a class="text-link" href="https://cfladies.ru/" target="_blank">https://cfladies.ru/</a>.
    </p>
    <p>
      Политика конфиденциальности ООО «Первые Леди» (далее – Политика) является приложением к Правилам использования
      сайта и их неотъемлемой частью. Принимая условия Правил использования сайта, Пользователь автоматически принимает
      условия настоящей Политики.
    </p>
  </div>

  <!-- 1. Термины и определения. -->
  <div class="contents__content">
    <h3>1. Термины и определения.</h3>
    <p><span class="bold">Стороны</span> – Администрация и любой зарегистрированный на сайте Пользователь.</p>
    <p>
      <span class="bold">Передающая сторона</span> (раскрывающая) – Сторона, предоставляющая другой Стороне доступ к
      Конфиденциальной информации, обладателем которой она является, в том числе путем передачи Конфиденциальной
      информации как на материальном носителе, так и на любых электронных носителях или передаваемая посредством сети
      Интернет, и/или предъявляющая требование к другой Стороне о соблюдении конфиденциальности информации, содержащейся
      в материалах, разрабатываемых другой Стороной или передаваемых другой Стороне в ходе исполнения договора,
      заключенного Сторонами или в ходе ведения переговоров о заключении договора.
    </p>
    <p>
      <span class="bold">Принимающая сторона</span> (получающая) – Сторона, получающая от другой Стороны доступ к
      Конфиденциальной информации, обладателем которой является Передающая сторона.
    </p>
    <p>
      Конфиденциальная информация – информация, признаваемая конфиденциальной в соответствии с законодательством
      Российской Федерации, локальными нормативными актами Передающей стороны, с которыми ознакомлена Принимающая
      сторона, иная информация, в отношении которой Передающей стороной заявлено требование о соблюдении ее
      конфиденциальности, в том числе информация, указанная в качестве конфиденциальной в договорах между Сторонами,
      включая информацию, составляющую коммерческую тайну и информацию, относящуюся к персональным данным.
    </p>
    <p>
      <span class="bold">Конфиденциальность информации</span> – обязательное для выполнения лицом, получившим доступ к
      определенной информации, требование не передавать такую информацию третьим лицам без предварительного письменного
      согласия ее обладателя.
    </p>
    <p>
      <span class="bold">Носители информации</span> – материальные объекты, в которых информация, составляющая
      Конфиденциальную информацию, находит свое отображение в виде символов, технических решений и процессов.
    </p>
    <p>
      <span class="bold">Передача Конфиденциальной информации</span> – передача информации, зафиксированной в качестве
      конфиденциальной, как на материальном носителе, так и на любых электронных носителях, или передаваемая посредством
      сети Интернет, Передающей стороной Принимающей стороне или Принимающей стороной третьим лицам с согласия
      Передающей стороны, а также с согласия иных лиц (<span class="italic"
        >если получение их согласия предусмотрено</span
      >) или на ином законном основании при условии сохранения конфиденциальности информации.
    </p>
    <p>
      <span class="bold"></span>Разглашение Конфиденциальной информации – действие или бездействие, в результате
      которого Конфиденциальная информация в любой возможной форме (устной, письменной, иной форме, в том числе с
      использованием технических средств) становится известной третьим лицам без согласия Передающей стороны, а также
      согласия иных лиц (<span class="italic">если получение их согласия предусмотрено</span>).
    </p>
  </div>

  <!-- 1. Общие положения -->
  <div class="contents__content">
    <h3>1. Общие положения.</h3>
    <p>
      1.1. Стороны соглашаются считать весь объем информации, предоставляемой друг другу в рамках взаимодействия при
      регистрации Пользователя и получения доступа к сайту и использовании его функционала, конфиденциальной информацией
      (в пределах, допускаемых действующим законодательством РФ – коммерческой тайной).
    </p>
    <p>
      1.2. В рамках настоящей Политики каждая из сторон в зависимости от того, раскрывает она или получает
      конфиденциальную информацию, может выступать как в качестве Принимающей стороны, так и в качестве Передающей
      стороны.
    </p>
    <p>
      1.3. Положения настоящей Политики распространяются на Конфиденциальную информацию Стороны независимо от вида
      носителя, на котором она зафиксирована.
    </p>
    <p>
      1.4. Информация, доступ к которой предоставляется Принимающей стороне без передачи материального носителя, и
      содержащаяся в информационных ресурсах, используемых Передающей стороной, включая сайт, доступ к которым
      предоставляется Принимающей стороне без передачи материального носителя с использованием сети Интернет, в любом
      случае является Конфиденциальной информацией Передающей стороны, без дополнительного указания (требования) о
      сохранении ее конфиденциальности.
    </p>
    <p>
      1.5. По каналам связи доступ к Конфиденциальной информации Передающей стороны производится при условии наличия у
      Принимающей стороны защищенных каналов передачи информации.
    </p>
    <p>
      1.6. Передача и обработка конфиденциальной информации, относящейся к персональным данным, производится в
      соответствии с требованиями Конституции Российской Федерации, Федерального закона РФ от 27 июля 2006 года No
      152-ФЗ «О персональных данных», настоящей Политики, Правил обработки персональных данных. Пользователи,
      присоединяясь к настоящей Политике, гарантируют выполнение требований действующего законодательства в части защиты
      информации.
    </p>
  </div>

  <!-- 2. Обязательства. -->
  <div class="contents__content">
    <h3>2. Обязательства.</h3>
    <p>
      2.1. Каждая сторона, получающая конфиденциальную информацию (Получающая сторона) от другой стороны (Передающая
      сторона), не имеет права сообщать конфиденциальную информацию кому-либо без прямого на то разрешения Передающей
      стороны и должна принимать все разумные меры для защиты этой информации, включая, в частности меры, которые она
      принимает для защиты собственной конфиденциальной информации/коммерческой тайны.
    </p>
    <p>
      Не является разглашением предоставление конфиденциальной информации третьим лицам при наличии у Передающей стороны
      письменного согласия другой стороны, на ее раскрытие.
    </p>
    <p>2.2. Условия о конфиденциальности не распространяются на сведения, которые:</p>
    <ul>
      <li>
        являются либо становятся общеизвестными не по вине Принимающей стороны (при условии подтверждения
        соответствующими доказательствами);
      </li>
      <li>
        получены сторонами от третьих лиц и общедоступных источников информации, в том числе, но не ограничиваясь, в
        сети Интернет, без обязательства о сохранении конфиденциальности;
      </li>
      <li>
        сторона обязана раскрыть в соответствии с требованиями действующего законодательства. Такие сведения могут быть
        предоставлены только в адрес органов, имеющих необходимые полномочия, в порядке, установленном действующим
        законодательством.
      </li>
    </ul>
    <p>
      2.3. Администрация принимает технические и организационно-правовые меры в целях обеспечения защиты информации,
      которую стороны предоставляют (передают, раскрывают) друг другу в ходе сотрудничества по предоставлению доступа к
      сайту, и персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения,
      блокирования, копирования, распространения, а также от иных неправомерных действий, путем внутренних проверок
      процессов сбора, хранения и обработки данных и мер безопасности, а также осуществления мер по обеспечению
      физической безопасности данных для предотвращения несанкционированного доступа к информации и персональным данным.
    </p>
    <p>
      2.4. Администрация вправе агрегировать, систематизировать и анализировать получаемую от Пользователя информацию, в
      том числе конфиденциальную, с целью создания информационно-аналитических отчетов разного рода и баз данных, при
      этом Администрация гарантирует нераспространение и сохранность конфиденциальной информации, содержащейся в отчетах
      и базах данных в соответствии с Правилами и действующим законодательством. Обладателем исключительных прав на
      такие информационно-аналитические отчеты и базы данных, как на объекты интеллектуальной собственности, является
      Администрация.
    </p>
    <p>2.5. Получающая сторона обязуется:</p>
    <ul>
      <li>
        использовать раскрытую информацию исключительно в целях реализации взаимодействия сторон в целях использования и
        предоставления доступа к сайту;
      </li>
      <li>
        ограничить перечень лиц, имеющих доступ к конфиденциальной информации, исключительно своими сотрудниками,
        непосредственно участвующими во взаимодействии при реализации Правил.
      </li>
    </ul>
    <p>
      2.6. Принимающая сторона обязуется соблюдать конфиденциальность в отношении Конфиденциальной информации Передающей
      стороны, в том числе не допускать ее разглашения и не использовать во вред Передающей стороне, и обеспечивать
      специальные меры охраны и использования Конфиденциальной информации Передающей стороны, при этом уровень охраны
      Конфиденциальной информации Передающей стороны не должен быть ниже, чем для охраны собственной конфиденциальной
      информации Принимающей стороны.
    </p>
    <p>
      Принимающая сторона вправе самостоятельно определять способы защиты Конфиденциальной информации Передающей
      стороны.
    </p>
  </div>

  <!-- 3. Ответственность. Разрешение споров. -->
  <div class="contents__content">
    <h3>3. Ответственность. Разрешение споров.</h3>
    <p>
      3.1. За каждое нарушение предусмотренных Политикой обязательств, Принимающая сторона обязуется возместить другой
      стороне документально подтвержденный реальный ущерб в полном объеме, возникший в результате такого нарушения.
    </p>
    <p>
      3.2. Споры и разногласия между Сторонами, связанные с применением и/или использованием настоящей Политики, Стороны
      будут пытаться разрешить путем переговоров. При не достижении согласия споры подлежат рассмотрению в суде, в
      порядке, предусмотренном действующим законодательством Российской Федерации.
    </p>
    <p>3.3. При разрешении споров Стороны применяют нормы права Российской Федерации.</p>
  </div>

  <!-- 4. Срок действия. Прочие положения. -->
  <div class="contents__content">
    <h3>4. Срок действия. Прочие положения.</h3>
    <p>
      4.1. Политика вступает в силу и становится обязательной для Сторон с момента принятия Пользователем Правил
      использования сайта, размещенными на сайте по адресу:
      <a class="text-link" href="https://cfladies.ru/" target="_blank">https://cfladies.ru/</a> (присоединения к
      Правилам).
    </p>
    <p>В случае несогласия с условиями Политики Пользователь обязуется прекратить использование сайта.</p>
    <p>
      4.2. Прекращение Пользователем использования сайта не освобождает Принимающую сторону от исполнения обязательств,
      принятых в соответствии с настоящей Политикой в период использования сайта, в отношении Конфиденциальной
      информации Передающей стороны, переданной Принимающей стороне до прекращения действия настоящей Политики, а также
      не освобождает от ответственности, установленной законодательством Российской Федерации и настоящей Политикой.
    </p>
    <p>
      4.3. Обязательства, установленные Политикой, в части охраны конфиденциальной информации, действительны бессрочно с
      момента передачи конфиденциальной информации.
    </p>
  </div>

  <!-- Реквизиты -->
  <div class="details">
    <p><span class="bold">Общество с ограниченной ответственностью «Первые Леди» (ООО «Первые Леди»)</span></p>
    <p><span class="bold">Юридический адрес:</span> 115035, город Москва, Садовническая ул, д. 72 стр. 1, помещ.2/1</p>
    <p><span class="bold">ИНН</span> 9705213124 <span class="bold">ОГРН</span> 1237700833028</p>
  </div>
</div>
